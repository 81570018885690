body {
  background: var(--bs-black);
}

.app-field:not(.field-type-button):not(.field-type-submit) {
  border-bottom: 2px dashed var(--bs-body-bg);
}

.app-fields > .app-field:only-child, .app-field > .app-input {
  border-bottom: 0;
}

.app-field > label {
  color: var(--bs-body-color);
  font-size: .7rem;
}

.app-fields > .app-field.field-type-button:last-child .app-button, .app-fields > .app-field.field-type-submit:last-child .app-button {
  border-radius: 0 0 3px 3px;
}

.app-field.field-type-checkbox {
  align-items: center;
  width: auto;
  padding: .75rem 1rem;
  display: flex;
}

.app-fields > .field-type-checkbox:last-child {
  border-bottom: 0;
}

.app-field.field-type-checkbox .app-input {
  appearance: checkbox;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin: 0 !important;
}

.app-field.field-type-checkbox label {
  flex-grow: 1;
  order: 1;
  margin: -1rem 0 -1rem 1rem;
  padding: 1rem 0;
}

.app-type-checkboxes .error {
  color: var(--bs-danger);
}

.app-field.field-type-rating-emotions .error .gl-star-rating--stars {
  background-color: var(--bs-danger);
}

.app-field.field-type-rating, .app-field.field-type-rating-emotions {
  border-bottom: 2px solid #0000000d;
  flex-flow: column;
  width: auto;
  padding-bottom: 10px;
  display: flex;
}

.app-field.field-type-rating .gl-star-rating, .app-field.field-type-rating-emotions .gl-star-rating {
  padding-left: 20px;
}

.app-field.field-type-rating .error .gl-star-rating--stars, .app-field.field-type-rating-emotions .error .gl-star-rating--stars {
  background-color: var(--bs-danger);
}

.rating-emotions ~ .gl-star-rating--stars > span {
  opacity: .5;
}

.rating-emotions ~ .gl-star-rating--stars > span:hover, .rating-emotions ~ .gl-star-rating--stars > span.gl-selected {
  opacity: 1;
}

.app-button:not(.no-hover):active, .app-button:not(.no-hover):hover {
  background-color: var(--bs-gray-400) !important;
  color: var(--bs-dark) !important;
}

.app-button.bg-dark:not(.no-hover):active, .app-button.bg-dark:not(.no-hover):hover {
  background-color: var(--bs-black);
  color: #fff !important;
}

.app-toast {
  opacity: 1;
  border-radius: 3px;
  font-weight: 600;
  inset: 5px 5px auto;
}

.app-toaster > .app-toast:nth-child(2) {
  top: 70px;
}

.app-toaster > .app-toast:nth-child(3) {
  top: 140px;
}

.app-toaster > .app-toast:nth-child(4) {
  top: 210px;
}

.app-nav.bg-dark > li {
  background: var(--bs-dark);
  color: #fff;
  cursor: pointer;
  border: 0 solid #000;
  border-width: 0 1px;
}

.app-nav.bg-dark > li.active, .app-nav.bg-dark > li:active, .app-nav.bg-dark > li:hover {
  background: #000;
}

.app-nav.rounded > li:first-child {
  border-left: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.app-nav.rounded > li:last-child {
  border-right: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.app-bottom-nav {
  bottom: 20px;
  left: 1rem;
  right: 1rem;
}

.app-dropdown {
  position: relative;
}

.app-dropdown .app-nav {
  z-index: 2;
  flex-direction: column;
  gap: .25rem;
  min-width: 120px;
  display: none;
  position: absolute;
  bottom: 3.05rem;
  right: 0;
}

.app-dropdown .app-nav > li {
  box-sizing: border-box;
  border-radius: 3px;
}

.app-dropdown.open .app-nav {
  display: flex;
}

.app-dropdown.open:after {
  content: "";
  z-index: 1;
  background: #00000080;
  position: fixed;
  inset: 0;
}

.app-card.active, .app-card:not(.no-hover):active, .app-card:not(.no-hover):hover {
  background: var(--bs-gray-200) !important;
}

.app-fields {
  margin: 0 1rem;
  box-shadow: 2px 4px 3px -2px #0000000d;
}

.app-toggle-password {
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
  display: block;
  position: absolute;
  bottom: 2px;
  right: 0;
  display: flex !important;
}

.app-loader {
  top: 0 !important;
}

.app-loader-w {
  background: var(--bs-black);
}

.app-loader-w:after {
  background: url("favicon-white.cac9a74f.svg") center / 80px no-repeat;
  border: 0;
  border-radius: 0;
  width: 80px;
  height: 80px;
  animation-name: wobble;
  animation-duration: 1.5s;
}

.app-loader-animated {
  z-index: 5050;
  background: #000;
}

.app-loader-animated:after {
  background: url("logo-animated.c549d04e.gif") center / 250px no-repeat;
  border: 0;
  border-radius: 0;
  width: 250px;
  height: 80px;
  animation: none;
}

.app-avatar {
  border: 2px solid var(--bs-gray-200);
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 50px;
  height: 50px;
  margin-bottom: 19px;
  font-size: 10px;
  position: relative;
}

.app-avatar[data-name]:after {
  content: attr(data-name);
  color: #000000b3;
  text-align: center;
  white-space: nowrap;
  font-size: 11px;
  position: absolute;
  top: 54px;
  left: 0;
  right: 0;
  overflow: hidden;
}

.app-avatar:hover:after, .app-avatar:active:after {
  display: block;
}

.dark-mode, .dark-mode .app-content {
  background: var(--bs-black) !important;
}

.app-uploader:after {
  content: "";
  background: linear-gradient(to bottom, transparent, var(--bs-black));
  z-index: 4990;
  position: fixed;
  inset: 0;
}

.drop-shadow-left {
  box-shadow: -4px 3px 3px -2px #0000000d;
}

.drop-shadow-dark-left {
  box-shadow: -2px 2px 2px -2px #0000004d;
}

.drop-shadow-right {
  box-shadow: 4px 3px 3px -2px #0000000d;
}

.drop-shadow-dark-right {
  box-shadow: 2px 2px 2px -2px #00000059;
}

.favicon {
  background: var(--bs-black);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.section-hero {
  background-color: var(--bs-black);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  margin: 0;
  padding: 0 1rem;
  display: flex;
  position: relative;
}

.section-hero:before {
  content: attr(data-text);
  z-index: 1;
  color: #fff;
  background: #00000026;
  justify-content: flex-end;
  padding: 1rem;
  font-size: 11px;
  font-style: italic;
  display: flex;
  position: absolute;
  inset: 0;
}

.section-hero .logo {
  z-index: 2;
}

.section-hero .title {
  text-align: center;
  z-index: 2;
  margin: 8px 0 0;
  line-height: 1;
}

.section-hero .video {
  object-fit: cover;
  width: 100%;
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
}

.section-details {
  z-index: 2;
  min-width: 200px;
  max-width: 350px;
  padding: 0 1rem;
  display: inline-block;
  position: relative;
}

.section-body p {
  margin: 0;
  line-height: 1.5;
}

.section-body > * + :not(p) {
  margin-top: 1rem !important;
}

.section-body > p + p, .section-body > * + ul, .section-body > * + ol {
  margin-top: .5rem !important;
}

.section-body ul, .section-body ol {
  margin: 0;
  padding: 0 0 0 1.25rem;
  line-height: 1.5;
  list-style-position: outside;
}

.section-body li {
  margin-bottom: .25rem;
}

.dark-mode .section-body h1, .dark-mode .section-body .h1, .dark-mode .section-body h2, .dark-mode .section-body .h2, .dark-mode .section-body h3, .dark-mode .section-body .h3, .dark-mode .section-body h4, .dark-mode .section-body .h4, .dark-mode .section-body p, .dark-mode .section-body li {
  color: #fff;
}

.dark-mode .section-body {
  background: var(--bs-black) !important;
}

.dark-mode .bg-dark-mode {
  background: var(--bs-yellow) !important;
  color: var(--bs-black) !important;
}

.section-player {
  background-color: #000;
}

.section-player > * {
  width: 100%;
}

.embed-fs {
  border: 0;
  width: 100vw;
  height: 100vh;
}

.progress-circle {
  border: 3px dashed #00000040;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 10px;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: 800;
  display: flex;
  transform: rotate(45deg);
}

.progress-circle > *, .progress-circle-icon > * {
  transform: rotate(-45deg);
}

.progress-circle.p-25, .progress-circle-icon.p-25 {
  border-top-color: var(--bs-yellow);
  border-top-style: solid;
}

.progress-circle.p-50, .progress-circle-icon.p-50 {
  border-top-color: var(--bs-yellow);
  border-top-style: solid;
  border-right-color: var(--bs-yellow);
  border-right-style: solid;
}

.progress-circle.p-75, .progress-circle-icon.p-75 {
  border-color: var(--bs-yellow);
  border-style: solid solid solid dashed;
  border-left-color: #00000040;
}

.progress-circle.p-100, .progress-circle-icon.p-100 {
  border-color: var(--bs-black);
  border-style: solid;
}

.progress-circle-icon {
  justify-content: center;
  align-items: center;
  display: flex;
  transform: translateY(-50%)rotate(45deg) !important;
}

.progress-circle-icon > * {
  font-size: 10px;
  font-weight: 800;
}

.progress-line {
  background: linear-gradient(90deg, var(--bs-green) 0%, var(--bs-gray-300) 0%);
  padding-bottom: 2px;
}

.progress-line .icon-crop {
  background: #fff;
  height: 16px;
  overflow: hidden;
}

.progress-line .icon {
  color: var(--bs-green);
  transform: rotate(14deg);
  font-size: 23px !important;
}

.page-application .section-hero:after {
  content: "";
  background: linear-gradient(to right, #998b00, #ffe800, #998b00) 0 0 / 200% 100%;
  height: 5px;
  animation: 8s infinite gradient;
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
}

@keyframes gradient {
  0% {
    background-position-x: 0%;
  }

  50% {
    background-position-x: 100%;
  }

  100% {
    background-position-x: 0%;
  }
}

.step-header {
  text-align: left;
  flex-direction: column;
  display: flex;
  position: relative;
}

.step-header .min-b b {
  min-width: 80px;
  display: inline-block;
}

.step-header-avatar {
  background-color: var(--bs-gray-400);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  position: absolute;
  top: -70px;
  left: auto;
}

.action-left-margin {
  margin-left: 59px !important;
}

.action-right-padding {
  padding-right: 49px !important;
}

.action-icon i, .card-icon i {
  vertical-align: top;
}

.action-legend {
  display: none;
}

.action-checkbox, .action-end-icon {
  width: 49px;
  color: var(--bs-gray-500);
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.action-checkbox:before {
  background: var(--bs-gray-500);
  content: "";
  opacity: 0;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  transition: all .3s;
  position: absolute;
}

.action-checkbox:after {
  content: "";
  border: 2px solid var(--bs-gray-400);
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.action-checkbox.checked:after {
  border-style: solid;
  border-width: 0 2px 2px 0;
  border-color: var(--bs-green);
  border-radius: 0;
  width: 8px;
  height: 17px;
  margin-top: -6px;
  transform: rotate(45deg);
}

.action-deadline {
  background: linear-gradient(90deg, var(--bs-green) 0%, var(--bs-gray-300) 0%);
  margin-top: 10px;
  padding-top: 2px;
}

.action-deadline div {
  color: var(--bs-gray-500);
  background: #fff;
  padding-top: 3px;
  font-size: 12px;
}

.step-actions {
  position: relative;
  overflow-x: hidden;
}

.step-actions div section {
  min-height: 39px;
}

.step-actions div section > .action-title:last-child {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.step-actions.dashed-line:before {
  content: "";
  border-left: 3px dotted var(--bs-gray-300);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 23px;
}

.step-actions .action-icon, .step-actions .card-icon {
  width: 17px;
  height: 17px;
  color: var(--bs-gray-700);
  border: 2px solid #0000;
  border-radius: 50%;
  padding: 9px;
  font-size: 17px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.step-actions div .action-icon i {
  transition: all .3s;
}

.step-actions div:hover .action-icon i {
  transform: scale(1.3);
}

.step-actions div:hover .action-checkbox:before {
  opacity: 1;
}

.step-actions div:hover .action-details {
  color: var(--bs-body-color) !important;
}

.step-actions-todo-list:before {
  top: 50px !important;
  bottom: 40px !important;
}

.step-actions-done-list.hide-items > div:nth-child(n+2) {
  display: none;
}

.step-actions-sticky-list .app-card {
  cursor: pointer;
  box-sizing: border-box;
  text-transform: uppercase;
  border-radius: 5px;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  gap: 1rem;
  width: 195px;
  height: 120px;
  margin-right: 1rem;
  padding: 1.618rem 1rem 0;
  font-size: 80%;
  display: flex;
}

.step-actions-sticky-list .app-card:hover {
  background: var(--bs-gray-400) !important;
}

.step-actions-sticky-list .action-icon {
  width: 25px;
  height: 25px;
  margin-top: -4px;
  font-size: 25px;
}

.step-program .min-b b {
  min-width: 80px;
  display: inline-block;
}

.step-payments .min-b b {
  min-width: 150px;
  display: inline-block;
}

.content-gallery {
  min-height: 243px;
}

.content-gallery .placeholder {
  width: 150px;
  height: 243px;
}

.content-gallery .post {
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  align-items: end;
  width: 150px;
  height: 243px;
  margin-right: 1rem;
  display: flex;
  position: relative;
}

.content-gallery .post:after, .content-list .post:after {
  content: "";
  background: #0000000d;
  position: absolute;
  inset: 0;
}

.content-gallery .post h3, .content-list .post h3 {
  text-shadow: 2px 2px 4px #000000b3;
  z-index: 100;
  font-size: 20px;
  line-height: 1.2;
}

.content-gallery .flickity-viewport {
  padding-bottom: 3px;
}

.page-profile .section-hero {
  background-image: url("bg-profile.8ce6a679.jpg");
}

.profile-avatar {
  cursor: pointer;
  background-color: var(--bs-gray-400);
  width: 150px;
  height: 150px;
  overflow: hidden;
}

.profile-avatar-nav {
  max-width: 300px;
}

.app-desktop .profile-avatar-nav {
  max-width: 200px;
}

.app-desktop .profile-avatar-nav :last-child {
  border-radius: 3px;
}

.page-profile .form-wrap {
  z-index: 1;
  position: relative;
}

.page-profile .field-button .app-button {
  color: #fff;
  cursor: pointer;
}

.page-profile .field-button .app-button:hover, .page-profile .field-button .app-button:active {
  background: #000 !important;
}

.profile-form {
  margin-bottom: 20px;
}

.content-list .post {
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  align-items: end;
  height: 30vw;
  display: flex;
  position: relative;
}

.content-title {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 20vw !important;
}

[data-columns] {
  gap: 1rem;
  display: flex;
}

[data-columns="2"] > * {
  flex: 1 0 calc(50% - 2.5rem);
}

[data-columns="3"] > * {
  flex-basis: calc(33.3333% - .666667rem);
}

.content-list > .column-js:nth-child(odd) > :nth-child(odd), .content-list > .column-js:nth-child(2n) > :nth-child(2n) {
  height: 50vw;
}

@media (width < 576px) {
  .content-list > .column-js:nth-child(odd) > :nth-child(odd) {
    height: 30vw;
  }

  .content-list > .column-js:nth-child(odd) > :nth-child(2n) {
    height: 50vw;
  }
}

.upload-video {
  visibility: hidden;
  background: var(--bs-gray-200);
  min-height: 300px;
  position: relative;
  overflow: hidden;
}

.upload-video:empty:before {
  content: "";
  opacity: .1;
  font-family: Material Icons;
  font-size: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.upload-example {
  display: none;
}

.page-upload-file .app-content {
  padding-bottom: 110px;
}

.upload-files-list {
  box-sizing: border-box;
  z-index: 4996;
  flex-wrap: wrap;
  gap: .5rem;
  min-height: 90px;
  list-style: none;
  position: fixed;
  bottom: 70px;
  left: 1rem;
  right: 1rem;
}

.upload-files-list li {
  cursor: pointer;
  background: none;
  border-radius: 50%;
  align-items: center;
  width: 70px;
  height: 70px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.upload-files-list li img {
  border-radius: 50%;
  width: 100%;
}

.upload-files-list li:before {
  content: "";
  z-index: 1;
  border: 3px solid #fff;
  border-radius: 50%;
  position: absolute;
  inset: 0;
}

.upload-files-list li:after {
  content: "x";
  color: #fff;
  background: #0000004d;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  display: flex;
  position: absolute;
  inset: 0;
}

.upload-files-list li + div {
  display: none;
}

.page-viewer .viewer-file {
  width: 100%;
  height: calc(100% - 50px);
}

.page-viewer .viewer-file img {
  object-fit: scale-down;
  width: 100%;
  height: auto;
}

.page-viewer .viewer-back {
  z-index: 1000;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px !important;
}

.page-viewer .flickity-slider {
  background: var(--bs-black);
  align-items: center;
  display: flex;
}

.page-reader .post-content li {
  margin-bottom: 5px;
}

.page-reader .post-content img {
  max-width: 100%;
  height: auto;
}

.page-reader .post-content .embed-fw {
  border: 0;
  width: 100vw;
  margin-left: -24px;
  margin-right: -24px;
}

.page-login {
  background: var(--bs-black) url("bg-login.4f41e7c9.jpg") no-repeat center;
  background-size: cover;
}

.page-login .logo {
  text-align: center;
  position: absolute;
  top: 130px;
  left: 2rem;
  right: 2rem;
}

.page-login .logo img {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.page-login .form {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  bottom: 130px;
  left: 2rem;
  right: 2rem;
}

.page-login .button-submit {
  cursor: pointer;
  height: 50px;
}

.page-login .button-submit:hover, .page-login .button-submit:active {
  background: var(--bs-dark) !important;
  color: #fff !important;
}

.page-login .button-login:hover, .page-login .button-login:active, .page-login .button-recover:hover, .page-login .button-recover:active {
  color: var(--bs-yellow) !important;
  background-color: #0000 !important;
}

.page-login .button-login, .page-login .recover .field-password, .page-login .recover .button-recover {
  display: none !important;
}

.page-login .recover .button-login {
  display: flex !important;
}

.tournament-challenge {
  background-image: linear-gradient(45deg, #087bb9 0%, #7c09bf 100%);
}

.feed-owner .app-avatar {
  width: 100px;
  height: 100px;
}

.feed-owner .app-avatar[data-name]:after {
  font-weight: bold;
  top: 110px;
}

.feed .post {
  position: relative;
  box-shadow: 4px 5px 5px #0000001a;
}

.feed .post-label {
  color: #ffffffb3;
  background-color: var(--bs-dark);
  z-index: 2;
  flex-direction: row;
  justify-content: space-between;
  font-size: .9rem;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.feed img {
  z-index: 1;
  border: 4px solid var(--bs-dark);
}

.rotate-right-1 {
  transform: rotate(3deg);
}

.rotate-right-2 {
  transform: rotate(5deg);
}

.rotate-left-1 {
  transform: rotate(-3deg);
}

.rotate-left-2 {
  transform: rotate(-5deg);
}

.rotate-right-1.touchstart {
  transform: rotate(3deg)scale(1.1);
}

.rotate-right-2.touchstart {
  transform: rotate(5deg)scale(1.1);
}

.rotate-left-1.touchstart {
  transform: rotate(-3deg)scale(1.1);
}

.rotate-left-2.touchstart {
  transform: rotate(-5deg)scale(1.1);
}

.fs-7 {
  font-size: .75rem !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.border-solid {
  border-style: solid !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-dashed {
  border-style: dashed !important;
}

.app-desktop-wrapper {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.app-desktop .app-desktop-hide {
  display: none !important;
}

.app-offline .disable-offline {
  pointer-events: none;
  opacity: .5;
}

.mw-250 {
  max-width: 250px;
}

.mw-350 {
  max-width: 350px;
}

.flex-shrink-2 {
  flex-shrink: 2;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.ptr--ptr {
  background: var(--bs-dark) !important;
  box-shadow: inset 0 -3px 4px #000000e6 !important;
}

.ptr--text, .ptr--icon {
  color: #fff !important;
}

@media (width >= 768px) {
  .app-toast {
    left: 0;
    right: 0;
  }

  .app-bottom-nav > .app-nav, .app-toast, .app-desktop-widther {
    max-width: 770px;
    margin-left: auto;
    margin-right: auto;
  }

  .app-bottom-nav {
    height: auto;
  }

  .app-nav-sm {
    width: 400px;
  }

  .app-nav-xs {
    width: 200px;
  }

  .app-dropdown .app-nav {
    bottom: 3.1rem;
  }

  .app-toast {
    width: 500px;
  }

  .app-loader-animated:after {
    background-size: 350px;
    width: 350px;
    height: 108px;
  }

  .mt-desktop {
    margin-top: 5vw !important;
  }

  .section-hero {
    background-position: center;
    height: 500px;
  }

  .section-hero .logo img {
    width: 280px;
    height: auto;
  }

  .step-header-avatar {
    width: 100px;
    height: 100px;
    top: -80px;
  }

  .page-application .section-hero:after {
    height: 7px;
    bottom: -7px;
  }

  .content-list .post {
    height: 20vw;
  }

  .content-list .post h3 {
    font-size: 2.6vw;
  }

  .content-list > .column-js:nth-child(odd) > :nth-child(odd), .content-list > .column-js:nth-child(2n) > :nth-child(2n) {
    height: 30vw;
  }

  .page-reader .post-content .embed-fw {
    width: 100%;
    margin: 0;
  }
}
/*# sourceMappingURL=index.7eb982f6.css.map */
