body {
    background: var(--bs-black);
}

.app-field:not(.field-type-button):not(.field-type-submit) {
    border-bottom: 2px dashed var(--bs-body-bg);
}

.app-fields>.app-field:only-child {
    border-bottom: 0;
}

.app-field>.app-input {
    border-bottom: 0;
}

.app-field>label {
    color: var(--bs-body-color);
    font-size: 0.7rem;
}

.app-fields>.app-field.field-type-button:last-child .app-button,
.app-fields>.app-field.field-type-submit:last-child .app-button {
    border-radius: 0 0 3px 3px;
}

.app-field.field-type-checkbox {
    display: flex;
    align-items: center;
    width: auto;
    padding: 0.75rem 1rem;
}

.app-fields>.field-type-checkbox:last-child {
    border-bottom: 0;
}

.app-field.field-type-checkbox .app-input {
    width: 20px;
    height: 20px;
    -webkit-appearance: checkbox;
    appearance: checkbox;
    margin: 0 !important;
    flex-shrink: 0;
}

.app-field.field-type-checkbox label {
    order: 1;
    padding: 0 0 0 1rem;
    flex-grow: 1;
    margin: -1rem 0 -1rem 1rem;
    padding: 1rem 0;
}

.app-type-checkboxes .error {
    color: var(--bs-danger);
}

.app-field.field-type-rating-emotions .error .gl-star-rating--stars {
    background-color: var(--bs-danger);
}

.app-field.field-type-rating,
.app-field.field-type-rating-emotions {
    display: flex;
    flex-flow: column;
    width: auto;
    padding-bottom: 10px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.app-field.field-type-rating .gl-star-rating,
.app-field.field-type-rating-emotions .gl-star-rating {
    padding-left: 20px;
}

.app-field.field-type-rating .error .gl-star-rating--stars,
.app-field.field-type-rating-emotions .error .gl-star-rating--stars {
    background-color: var(--bs-danger);
}

.rating-emotions~.gl-star-rating--stars>span {
    opacity: 0.5;
}

.rating-emotions~.gl-star-rating--stars>span:hover,
.rating-emotions~.gl-star-rating--stars>span.gl-selected {
    opacity: 1;
}

.app-button:not(.no-hover):active,
.app-button:not(.no-hover):hover {
    background-color: var(--bs-gray-400) !important;
    color: var(--bs-dark) !important;
}

.app-button.bg-dark:not(.no-hover):active,
.app-button.bg-dark:not(.no-hover):hover {
    background-color: var(--bs-black);
    color: #fff !important;
}

/* Custom */
.app-toast {
    opacity: 1;
    bottom: auto;
    top: 5px;
    left: 5px;
    right: 5px;
    border-radius: 3px;
    font-weight: 600;
}

.app-toaster>.app-toast:nth-child(2) {
    top: 70px;
}

.app-toaster>.app-toast:nth-child(3) {
    top: 140px;
}

.app-toaster>.app-toast:nth-child(4) {
    top: 210px;
}

.app-nav.bg-dark>li {
    border: 1px solid #000;
    border-width: 0 1px;
    background: var(--bs-dark);
    color: #fff;
    cursor: pointer;
}

.app-nav.bg-dark>li.active,
.app-nav.bg-dark>li:active,
.app-nav.bg-dark>li:hover {
    background: #000;
}

.app-nav.rounded>li:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: 0;
}

.app-nav.rounded>li:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-right: 0;
}

.app-bottom-nav {
    bottom: 20px;
    left: 1rem;
    right: 1rem;
}

.app-dropdown {
    position: relative;
}

.app-dropdown .app-nav {
    display: none;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    bottom: 3.05rem;
    right: 0;
    gap: 0.25rem;
    min-width: 120px;

}

.app-dropdown .app-nav>li {
    border-radius: 3px;
    box-sizing: border-box;
}

.app-dropdown.open .app-nav {
    display: flex;
}

.app-dropdown.open:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
}

.app-card.active,
.app-card:not(.no-hover):active,
.app-card:not(.no-hover):hover {
    background: var(--bs-gray-200) !important;
}

.app-fields {
    margin: 0 1rem;
    box-shadow: 2px 4px 3px -2px rgb(0 0 0 / 5%);
}

.app-toggle-password {
    display: block;
    width: 50px;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 2px;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.app-loader {
    top: 0 !important;
}

.app-loader-w {
    background: var(--bs-black);
}

.app-loader-w:after {
    background: url('../images/brand/favicon-white.svg') no-repeat center;
    border: 0;
    border-radius: 0;
    width: 80px;
    height: 80px;
    background-size: 80px;
    animation-duration: 1.5s;
    animation-name: wobble;
}

.app-loader-animated {
    background: #000;
    z-index: 5050;
}

.app-loader-animated:after {
    background: url('../images/brand/logo-animated.gif') no-repeat center;
    border: 0;
    border-radius: 0;
    width: 250px;
    height: 80px;
    background-size: 250px auto;
    animation: none;
}

.app-avatar {
    height: 50px;
    width: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid var(--bs-gray-200);
    position: relative;
    font-size: 10px;
    text-align: center;
    margin-bottom: 19px;
}

.app-avatar[data-name]:after {
    content: attr(data-name);
    color: rgba(0, 0, 0, 0.7);
    font-size: 11px;
    text-align: center;
    position: absolute;
    top: 54px;
    left: 0;
    right: 0;
    overflow: hidden;
    white-space: nowrap;
}

.app-avatar:hover:after,
.app-avatar:active:after {
    display: block;
}

.dark-mode,
.dark-mode .app-content {
    background: var(--bs-black) !important;
}

.app-uploader:after {
    content: '';
    background: linear-gradient(to bottom, transparent, var(--bs-black));
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 4990;
    position: fixed;
}

/* Main */
.drop-shadow-left {
    box-shadow: -4px 3px 3px -2px rgba(0, 0, 0, 0.05);
}

.drop-shadow-dark-left {
    box-shadow: -2px 2px 2px -2px rgb(0, 0, 0, 0.3);
}

.drop-shadow-right {
    box-shadow: 4px 3px 3px -2px rgba(0, 0, 0, 0.05);
}

.drop-shadow-dark-right {
    box-shadow: 2px 2px 2px -2px rgb(0, 0, 0, 0.35);
}


.favicon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--bs-black);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.section-hero {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0 1rem;
    height: 300px;
    background-color: var(--bs-black);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
}

.section-hero:before {
    content: attr(data-text);
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 1;
    padding: 1rem;
    font-size: 11px;
    font-style: italic;
    color: #fff;
    display: flex;
    justify-content: flex-end;
}

.section-hero .logo {
    z-index: 2;
}

.section-hero .title {
    margin: 8px 0 0;
    text-align: center;
    z-index: 2;
    line-height: 1;
}

.section-hero .video {
    object-fit: cover;
    height: 300px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.section-details {
    padding: 0 1rem;
    position: relative;
    z-index: 2;
    display: inline-block;
    max-width: 350px;
    min-width: 200px;
}

.section-body p {
    line-height: 1.5;
    margin: 0;
}

.section-body>*+*:not(p) {
    margin-top: 1rem !important;
}

.section-body>p+p {
    margin-top: 0.5rem !important;
}

.section-body>*+ul,
.section-body>*+ol {
    margin-top: 0.5rem !important;
}

.section-body ul,
.section-body ol {
    padding: 0 0 0 1.25rem;
    margin: 0;
    list-style-position: outside;
    line-height: 1.5;
}

.section-body li {
    margin-bottom: 0.25rem;
}

.dark-mode .section-body h1,
.dark-mode .section-body .h1,
.dark-mode .section-body h2,
.dark-mode .section-body .h2,
.dark-mode .section-body h3,
.dark-mode .section-body .h3,
.dark-mode .section-body h4,
.dark-mode .section-body .h4,
.dark-mode .section-body p,
.dark-mode .section-body li {
    color: #fff;
}

.dark-mode .section-body {
    background: var(--bs-black) !important;
}

.dark-mode .bg-dark-mode {
    background: var(--bs-yellow) !important;
    color: var(--bs-black) !important;
}

.section-player {
    background-color: #000;
}

.section-player>* {
    width: 100%;
}

.embed-fs {
    border: 0;
    width: 100vw;
    height: 100vh;
}

.progress-circle {
    border-width: 3px;
    border-style: dashed;
    border-color: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    transform: rotate(45deg);
}

.progress-circle>*,
.progress-circle-icon>* {
    transform: rotate(-45deg);
}

.progress-circle.p-25,
.progress-circle-icon.p-25 {
    border-top-color: var(--bs-yellow);
    border-top-style: solid;
}

.progress-circle.p-50,
.progress-circle-icon.p-50 {
    border-top-color: var(--bs-yellow);
    border-top-style: solid;
    border-right-color: var(--bs-yellow);
    border-right-style: solid;
}

.progress-circle.p-75,
.progress-circle-icon.p-75 {
    border-color: var(--bs-yellow);
    border-style: solid;
    border-left-color: rgba(0, 0, 0, 0.25);
    border-left-style: dashed;
}

.progress-circle.p-100,
.progress-circle-icon.p-100 {
    border-color: var(--bs-black);
    border-style: solid;
}

.progress-circle-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-50%) rotate(45deg) !important;
}

.progress-circle-icon>* {
    font-weight: 800;
    font-size: 10px;
}

.progress-line {
    padding-bottom: 2px;
    background: linear-gradient(90deg, var(--bs-green) 0%, var(--bs-gray-300) 0%);
}

.progress-line .icon-crop {
    height: 16px;
    overflow: hidden;
    background: #fff;
}

.progress-line .icon {
    color: var(--bs-green);
    -webkit-transform: rotate(14deg);
    transform: rotate(14deg);
    font-size: 23px !important;
}

/* Application */
.page-application .section-hero:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    background: linear-gradient(to right, #998b00, #ffe800, #998b00);
    background-size: 200% 100%;
    animation: gradient 8s ease infinite;
    height: 5px;
}

@keyframes gradient {
    0% {
        background-position-x: 0%;
    }

    50% {
        background-position-x: 100%;
    }

    100% {
        background-position-x: 0%;
    }
}

.step-header {
    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;
}

.step-header .min-b b {
    min-width: 80px;
    display: inline-block;
}

.step-header-avatar {
    height: 80px;
    width: 80px;
    top: -70px;
    left: auto;
    position: absolute;
    border-radius: 50%;
    background-color: var(--bs-gray-400);
}

.action-left-margin {
    margin-left: 59px !important;
}

.action-right-padding {
    padding-right: 49px !important;
}

.action-icon i,
.card-icon i {
    vertical-align: top;
}

.action-legend {
    display: none;
}

.action-checkbox,
.action-end-icon {
    width: 49px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--bs-gray-500);
}

.action-checkbox:before {
    border-radius: 50%;
    background: var(--bs-gray-500);
    content: '';
    width: 14px;
    height: 14px;
    position: absolute;
    opacity: 0;
    transition: all 0.3s ease;
}

.action-checkbox:after {
    content: '';
    width: 16px;
    height: 16px;
    border: 2px solid var(--bs-gray-400);
    border-radius: 50%;
}

.action-checkbox.checked:after {
    width: 8px;
    height: 17px;
    border-radius: 0;
    border-width: 0 2px 2px 0;
    border-style: solid;
    transform: rotate(45deg);
    border-color: var(--bs-green);
    margin-top: -6px;
}

.action-deadline {
    margin-top: 10px;
    padding-top: 2px;
    background: linear-gradient(90deg, var(--bs-green) 0%, var(--bs-gray-300) 0%);
}

.action-deadline div {
    font-size: 12px;
    padding-top: 3px;
    background: #fff;
    color: var(--bs-gray-500);
}

.step-actions {
    position: relative;
    overflow-x: hidden;
}

.step-actions div section {
    min-height: 39px;
}

.step-actions div section>.action-title:last-child {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.step-actions.dashed-line:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 23px;
    border-left: 3px dotted var(--bs-gray-300);
}

.step-actions .action-icon,
.step-actions .card-icon {
    width: 17px;
    height: 17px;
    font-size: 17px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: var(--bs-gray-700);
    border-radius: 50%;
    padding: 9px;
    border: 2px solid transparent;
}

.step-actions div .action-icon i {
    transition: all 0.3s ease;
}

.step-actions div:hover .action-icon i {
    transform: scale(1.3);
}

.step-actions div:hover .action-checkbox:before {
    opacity: 1;
}

.step-actions div:hover .action-details {
    color: var(--bs-body-color) !important;
}

.step-actions-todo-list:before {
    top: 50px !important;
    bottom: 40px !important;
}

.step-actions-done-list.hide-items>div:nth-child(n+2) {
    display: none;
}

.step-actions-sticky-list .app-card {
    height: 120;
    width: 195px;
    margin-right: 1rem;
    border-radius: 5px;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: row;
    gap: 1rem;
    cursor: pointer;
    padding: 1.618rem 1rem 0;
    box-sizing: border-box;
    text-transform: uppercase;
    font-size: 80%;
}

.step-actions-sticky-list .app-card:hover {
    background: var(--bs-gray-400) !important;
}

.step-actions-sticky-list .action-icon {
    font-size: 25px;
    width: 25px;
    height: 25px;
    margin-top: -4px;
}

.step-program .min-b b {
    min-width: 80px;
    display: inline-block;
}

.step-payments .min-b b {
    min-width: 150px;
    display: inline-block;
}

.content-gallery {
    min-height: 243px;
}

.content-gallery .placeholder {
    width: 150px;
    height: 243px;
}

.content-gallery .post {
    width: 150px;
    height: 243px;
    margin-right: 1rem;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: end;
    cursor: pointer;
    position: relative;
}

.content-gallery .post:after,
.content-list .post:after {
    content: '';
    background: rgba(0, 0, 0, 0.05);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.content-gallery .post h3,
.content-list .post h3 {
    text-shadow: 2px 2px 4px rgb(0 0 0 / 70%);
    font-size: 20px;
    line-height: 1.2;
    z-index: 100;
}


.content-gallery .flickity-viewport {
    padding-bottom: 3px;
}

/* Profile */
.page-profile .section-hero {
    background-image: url('../images/bg-profile.jpg');
}

.profile-avatar {
    height: 150px;
    width: 150px;
    overflow: hidden;
    cursor: pointer;
    background-color: var(--bs-gray-400);
}

.profile-avatar-nav {
    max-width: 300px;
}

.app-desktop .profile-avatar-nav {
    max-width: 200px;
}

.app-desktop .profile-avatar-nav *:last-child {
    border-radius: 3px;
}

.page-profile .form-wrap {
    position: relative;
    z-index: 1;
}

.page-profile .field-button .app-button {
    color: #fff;
    cursor: pointer;
}

.page-profile .field-button .app-button:hover,
.page-profile .field-button .app-button:active {
    background: #000 !important;
}

.profile-form {
    margin-bottom: 20px;
}

/* Content */
.content-list .post {
    height: 30vw;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: end;
    cursor: pointer;
    position: relative;
}

.content-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vw !important;
    flex-direction: column;
}


[data-columns] {
    display: flex;
    gap: 1rem;
}

[data-columns="2"]>* {
    flex-basis: calc((100% - 5rem) / 2);
    flex-shrink: 0;
    flex-grow: 1;
}

[data-columns="3"]>* {
    flex-basis: calc((100% - 2rem) / 3);
}

.content-list>.column-js:nth-child(odd)>*:nth-child(odd),
.content-list>.column-js:nth-child(even)>*:nth-child(even) {
    height: 50vw;
}

@media (width <576px) {
    .content-list>.column-js:nth-child(odd)>*:nth-child(odd) {
        height: 30vw;
    }

    .content-list>.column-js:nth-child(odd)>*:nth-child(even) {
        height: 50vw;
    }
}

/* Uploads */
.upload-video {
    visibility: hidden;
    overflow: hidden;
    background: var(--bs-gray-200);
    position: relative;
    min-height: 300px;
}

.upload-video:empty:before {
    content: '\e02c';
    font-family: 'Material Icons';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 100px;
    opacity: 0.1;
}

.upload-example {
    display: none;
}

/* Upload Form */

/* Upload File */
.page-upload-file .app-content {
    padding-bottom: 110px;
}

.upload-files-list {
    list-style: none;
    position: fixed;
    left: 1rem;
    right: 1rem;
    bottom: 70px;
    flex-wrap: wrap;
    gap: 0.5rem;
    min-height: 90px;
    box-sizing: border-box;
    z-index: 4996;
}

.upload-files-list li {
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    background: transparent;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.upload-files-list li img {
    width: 100%;
    border-radius: 50%;
}

.upload-files-list li:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 3px solid #fff;
    z-index: 1;
}

.upload-files-list li:after {
    content: 'x';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-files-list li+div {
    display: none;
}

/* Viewer */
.page-viewer .viewer-file {
    width: 100%;
    height: calc(100% - 50px);
}

.page-viewer .viewer-file img {
    width: 100%;
    height: auto;
    object-fit: scale-down;
}

.page-viewer .viewer-back {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    height: 50px !important;
}

.page-viewer .flickity-slider {
    display: flex;
    background: var(--bs-black);
    align-items: center;
}

/* Reader */
.page-reader .post-content li {
    margin-bottom: 5px;
}

.page-reader .post-content img {
    max-width: 100%;
    height: auto;
}

.page-reader .post-content .embed-fw {
    border: 0;
    width: 100vw;
    margin-left: -24px;
    margin-right: -24px;
}

/* Login */
.page-login {
    background: var(--bs-black) url('../images/bg-login.jpg') no-repeat center;
    background-size: cover;
}

.page-login .logo {
    position: absolute;
    top: 130px;
    left: 2rem;
    right: 2rem;
    text-align: center;
}

.page-login .logo img {
    width: 100%;
    height: auto;
    max-width: 300px;
}

.page-login .form {
    position: absolute;
    bottom: 130px;
    left: 2rem;
    right: 2rem;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.page-login .button-submit {
    height: 50px;
    cursor: pointer;
}

.page-login .button-submit:hover,
.page-login .button-submit:active {
    background: var(--bs-dark) !important;
    color: #fff !important;
}

.page-login .button-login:hover,
.page-login .button-login:active,
.page-login .button-recover:hover,
.page-login .button-recover:active {
    color: var(--bs-yellow) !important;
    background-color: transparent !important;
}

.page-login .button-login,
.page-login .recover .field-password,
.page-login .recover .button-recover {
    display: none !important;
}

.page-login .recover .button-login {
    display: flex !important;
}

/* Tournament */
.tournament-challenge {
    background-image: linear-gradient(45deg, #087bb9 0%, #7c09bf 100%);
}

.feed-owner .app-avatar {
    width: 100px;
    height: 100px;
}

.feed-owner .app-avatar[data-name]:after {
    top: 110px;
    font-weight: bold;
}

.feed .post {
    position: relative;
    box-shadow: 4px 5px 5px 0px rgb(0 0 0 / 10%);
}

.feed .post-label {
    color: rgba(255, 255, 255, 0.7);
    background-color: var(--bs-dark);
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    flex-direction: row;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.feed img {
    z-index: 1;
    border: 4px solid var(--bs-dark);
}

.rotate-right-1 {
    transform: rotate(3deg);
}

.rotate-right-2 {
    transform: rotate(5deg);
}

.rotate-left-1 {
    transform: rotate(-3deg);
}

.rotate-left-2 {
    transform: rotate(-5deg);
}

.rotate-right-1.touchstart {
    transform: rotate(3deg) scale(1.1);
}

.rotate-right-2.touchstart {
    transform: rotate(5deg) scale(1.1);
}

.rotate-left-1.touchstart {
    transform: rotate(-3deg) scale(1.1);
}

.rotate-left-2.touchstart {
    transform: rotate(-5deg) scale(1.1);
}

/* Utilities */
.fs-7 {
    font-size: 0.75rem !important;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.border-solid {
    border-style: solid !important;
}

.border-dotted {
    border-style: dotted !important;
}

.border-dashed {
    border-style: dashed !important;
}

.app-desktop-wrapper {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.app-desktop .app-desktop-hide {
    display: none !important;
}

.app-offline .disable-offline {
    pointer-events: none;
    opacity: 0.5;
}

.mw-250 {
    max-width: 250px;
}

.mw-350 {
    max-width: 350px;
}

.flex-shrink-2 {
    flex-shrink: 2;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

/* Plugin */
.ptr--ptr {
    background: var(--bs-dark) !important;
    box-shadow: inset 0 -3px 4px rgba(0, 0, 0, 0.9) !important;
}

.ptr--text,
.ptr--icon {
    color: white !important
}

/* Medium */
@media (width >=768px) {

    .app-toast {
        left: 0;
        right: 0;
    }

    .app-bottom-nav>.app-nav,
    .app-toast,
    .app-desktop-widther {
        max-width: 770px;
        margin-left: auto;
        margin-right: auto;
    }

    .app-bottom-nav {
        height: auto;
    }

    .app-nav-sm {
        width: 400px;
    }

    .app-nav-xs {
        width: 200px;
    }

    .app-dropdown .app-nav {
        bottom: 3.1rem;
    }

    .app-toast {
        width: 500px;
    }

    .app-loader-animated:after {
        width: 350px;
        height: 108px;
        background-size: 350px auto;
    }

    .mt-desktop {
        margin-top: 5vw !important;
    }

    .section-hero {
        height: 500px;
        background-position: center;
    }

    .section-hero .logo img {
        width: 280px;
        height: auto;
    }

    .step-header-avatar {
        width: 100px;
        height: 100px;
        top: -80px;
    }

    /* Application */
    .page-application .section-hero:after {
        height: 7px;
        bottom: -7px;
    }

    /* Content */
    .content-list .post {
        height: 20vw;
    }

    .content-list .post h3 {
        font-size: 2.6vw;
    }

    .content-list>.column-js:nth-child(odd)>*:nth-child(odd),
    .content-list>.column-js:nth-child(even)>*:nth-child(even) {
        height: 30vw;
    }

    /* Reader */
    .page-reader .post-content .embed-fw {
        width: 100%;
        margin: 0;
    }
}